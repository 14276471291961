*,
*::before,
*::after,
div,
header,
footer,
section,
article,
main {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul[class],
ol[class] {
  list-style: none;
}

input,
button,
textarea,
select {
  outline: none;
}

button, a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  margin: 0;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}